import auth0 from 'auth0-js';
import invariant from 'tiny-invariant';

const webAuthInstance: auth0.WebAuth | null = null;

type GetWebAuthType = {
  appRedirect?: boolean;
  invitedEmail?: string;
};

export const getWebAuth = ({ appRedirect, invitedEmail }: GetWebAuthType) => {
  if (!webAuthInstance) {
    try {
      const baseUrl = `${location.protocol}//${location.host}`;
      const redirectPath = appRedirect
        ? 'auth-app/callback'
        : invitedEmail
        ? `authorize/callback?invited_email=${encodeURIComponent(invitedEmail)}`
        : 'authorize/callback';

      // Important: setting AUTH0_REDIRECT_URI is required for SSO login in Firebase+GCF, in Vercel it was not required
      const redirectUri = `${window.ENV.AUTH0_REDIRECT_URI || baseUrl}/app/${redirectPath}`;

      invariant(window.ENV.AUTH0_DOMAIN, 'AUTH0_DOMAIN is not defined');
      invariant(window.ENV.AUTH0_CLIENT_ID, 'AUTH0_CLIENT_ID is not defined');

      const params = {
        overrides: { __tenant: window.ENV.AUTH0_TENANT },
        domain: window.ENV.AUTH0_DOMAIN,
        clientID: window.ENV.AUTH0_CLIENT_ID,
        redirectUri,
        audience: window.ENV.AUTH0_AUDIENCE,
        responseType: 'code',
        universalLoginPage: false,
      };
      return new auth0.WebAuth(params);
    } catch (error) {
      return null;
    }
  }

  return webAuthInstance;
};
